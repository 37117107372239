/* eslint-disable */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBrandList, ClearFormSetFutios, addNewBrandList, editBrandList, getAllUserList, getCategoryList, getAllBrandList, addCategoryList, updateCategoryList, getSubCategoryList, getAllCategoryList, addSubCategoryList, updateSubCategoryList, getProductList, getAllSubCategoryList, addProductList, updateProductList, getCityList, addCityList, updateCityList, getStateList, getPinCodeList, getAllCityList, getRegionMasterList, addRegionMasterList, updateRegionMasterList, getUserList, getAllRegionList, addUserList, updateUserList, getWareHouseList, getAllStateList, SearchPincodeList, StartCommonLoader, ErrorMessageFromRedux, addWareHouseList, updateWareHouseList, getAllWareHouseList, StoredDetailReport, getAllProductList, StoredLevelReport, GetFranchiseTypeList, updateFranchiseTypeList, addFranchiseTypeList, GetFranchiseUserTypeList, GetAllFranchiseTypeList, addFranchiseUserTypeList, GetAllDealerFranchiseUserTypeList, GetProductGroupList, addProductGroupList, updateProductGroupList, GetAllProductGroupList, updateFranchiseUserTypeList, FranchiseOrdersReportList, GetOrderReportDetails, GetPOApprovalsOrderList, GetWareHouseApprovalsOrderList, PendingForAccountsOrderList, PendingForStoresOrderList, GetDispatchList, addDispatchList, updateDispatchList, GetAllDispatchList, setStoreAccessoriesList, setStoreOptionList, setStoreOptionListClear, setStoreCouriersList, setStoreAccessoriesReport, setStorePendingDispatchList, setStoreOptionCouriersList, setStorePendingDeliveryList, setStoreDeliveredList, setStoreRejectedOrderList, clearRedux, setStoreModalInvoiceDetails, setStoreInvoiceList, setStoreEInvoiceSuccessList, setStoreEInvoiceFailureList, setStoreCancelledOrderList, GetStockInwardList, addStateList, updateStateList, getBoxList, getAllBoxList, addBoxList, updateBoxList, addPurchaseList, StartMainCommonLoader, GetFrameList } from '../../redux/actions/adminActions';
import CommonLogout from '../../components/Common/CommonLogout';
import wsSend_request from './ws_request';
import OmsWarrantyAPI from '../OmsWarrantyAPI';
import { FILE_DOWNLOAD_URL } from '../../components/Shared/constant';

export const ws_response = ({ evt, ws }) => {
    return (async (dispatch, getState) => {
        const ws_onmessage = JSON.parse(evt.data);
        const { device_id, accessToken, searchPincodeList, getFrameList } = getState()?.adminReducers;

        // Sw notification setup
        if (ws_onmessage?.response?.status === 403) {
            if (accessToken !== "" && accessToken !== null) {
                CommonLogout(accessToken, dispatch);
                dispatch(StartCommonLoader(false));
                dispatch(StartMainCommonLoader({
                    url: "",
                    loader: false
                }));
            }
        };
        switch (ws_onmessage.url) {
            case "brand_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllBrandList(ws_onmessage?.response));
                        } else {
                            dispatch(getBrandList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getBrandList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "brand_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addNewBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "brand_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "category_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(getCategoryList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "category_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "category_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "subcategory_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllSubCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(getSubCategoryList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getSubCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "subcategory_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateCityList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "subcategory_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateSubCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllProductList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getProductList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getProductList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "product_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addProductList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateProductList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "district_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllCityList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getCityList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getCityList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "district_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addCityList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "district_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "state_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllStateList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getStateList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getStateList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "state_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addStateList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "state_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateStateList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "pin_code_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPinCodeList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPinCodeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "region_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllRegionList(ws_onmessage?.response?.data?.data))
                        } else {
                            dispatch(getRegionMasterList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getRegionMasterList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "region_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addRegionMasterList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "region_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateRegionMasterList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "user_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllUserList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getUserList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getUserList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "user_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addUserList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "user_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateUserList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "warehouse_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllWareHouseList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getWareHouseList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getWareHouseList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "warehouse_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addWareHouseList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "warehouse_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateWareHouseList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "wh_cancelled":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "wh_order_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_order_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_order_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "pin_code_search":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(StartCommonLoader(true));
                    dispatch(SearchPincodeList(ws_onmessage?.response?.data));
                    dispatch(ErrorMessageFromRedux({
                        API: "pin_code_search",
                        Error: "",
                    }));
                    setTimeout(() => {
                        dispatch(StartCommonLoader(false));
                    }, 1000);
                } else {
                    dispatch(StartCommonLoader(true));
                    dispatch(SearchPincodeList(""))
                    dispatch(ErrorMessageFromRedux({
                        API: "pin_code_search",
                        Error: "Incorrect PIN. Please try again.",
                    }));
                    setTimeout(() => {
                        dispatch(StartCommonLoader(false));
                    }, 1000);
                };
                break;
            case "store_frame_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StoredDetailReport(ws_onmessage?.response?.data));
                    } else {
                        dispatch(StoredDetailReport(ws_onmessage?.response?.data));
                    };
                };
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: ws_onmessage?.response?.data }))
                } else {
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: null }))
                };
                break;
            case "store_level_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StoredLevelReport(ws_onmessage?.response?.data));
                    } else {
                        dispatch(StoredLevelReport(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "franchise_type_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllFranchiseTypeList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetFranchiseTypeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetFranchiseTypeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "franchise_type_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addFranchiseTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "franchise_type_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateFranchiseTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "franchise_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDealerFranchiseUserTypeList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(GetFranchiseUserTypeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetFranchiseUserTypeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "franchise_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addFranchiseUserTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "franchise_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateFranchiseUserTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_group_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllProductGroupList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetProductGroupList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(GetProductGroupList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "product_group_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addProductGroupList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_group_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateProductGroupList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "order_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "st_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(setStoreOptionListClear({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "order_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.request?.status === "OPEN,SO_HALF_APPROVAL") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(GetPOApprovalsOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(GetPOApprovalsOrderList(ws_onmessage?.response?.data));
                        };
                    } else if (ws_onmessage?.request?.status === "SO_APPROVED" || ws_onmessage?.request?.status === "WH_PENDING") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(GetWareHouseApprovalsOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(GetWareHouseApprovalsOrderList(ws_onmessage?.response?.data));
                        };
                    } else if (ws_onmessage?.request?.status === "WH_APPROVED") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(PendingForAccountsOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(PendingForAccountsOrderList(ws_onmessage?.response?.data));
                        };
                    } else if (ws_onmessage?.request?.status === "AC_APPROVED") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(PendingForStoresOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(PendingForStoresOrderList(ws_onmessage?.response?.data));
                        };
                    } else if (ws_onmessage?.request?.status === "ST_APPROVED,HALF_DISPATCH") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStorePendingDispatchList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStorePendingDispatchList({
                                data: [],
                                pagination: {}
                            }))
                        };
                    } else if (ws_onmessage?.request?.status === "DISPATCHED") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStorePendingDeliveryList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStorePendingDeliveryList({
                                data: [],
                                pagination: {}
                            }))
                        };
                    } else if (ws_onmessage?.request?.status === "DELIVERED") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreDeliveredList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreDeliveredList({ data: [], pagination: {} }))
                        };
                    } else if (ws_onmessage?.request?.status === "REJECTED") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreRejectedOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreRejectedOrderList({ data: [], pagination: {} }))
                        };
                    } else if (ws_onmessage?.request?.status === "CANCELLED,RETURN,REPLACE") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreCancelledOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreCancelledOrderList({ data: [], pagination: {} }))
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "invoice_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.request?.invoice_status === "") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreInvoiceList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreInvoiceList({ data: [], pagination: {} }));
                            dispatch(setStoreEInvoiceSuccessList({ data: [], pagination: {} }));
                        };
                    } else if (ws_onmessage?.request?.status !== "CAN") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreEInvoiceSuccessList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreEInvoiceSuccessList({ data: [], pagination: {} }));
                        };
                    } else if (ws_onmessage?.request?.status == "CAN") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreEInvoiceFailureList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreEInvoiceFailureList({ data: [], pagination: {} }));
                        };
                    }
                }
                break;
            case "ac_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "ac_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "ac_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
            case "order_detail":
                if (ws_onmessage?.modal && ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        const payload = ws_onmessage?.response?.data;
                        if (ws_onmessage?.request?.qr_code) {
                            payload.qr_code = ws_onmessage?.request?.qr_code;
                        }
                        dispatch(setStoreModalInvoiceDetails(payload));
                    } else {
                        dispatch(setStoreModalInvoiceDetails(null));
                    }
                } else if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                        if (ws_onmessage?.request?.get_franchise === "yes" && ws_onmessage?.response?.data?.order?.warehouse_id) {
                            ws_onmessage?.response?.data?.order_items_list?.map((item) => {
                                const request = {
                                    "transmit": "broadcast",
                                    "url": "store_accessories_list",
                                    "request": {
                                        "status": "UNSOLD",
                                        "limit": "1000",
                                        "from_date": "",
                                        "to_date": "",
                                        "order_type": "",
                                        "warehouse_id": ws_onmessage?.response?.data?.order?.warehouse_id,
                                        "product_id": item?.product_id,
                                        "page_no": "1",
                                        "search": "",
                                        "order_by": "-updated_at",
                                        "timezone": "",
                                        "DeviceId": device_id
                                    }
                                }
                                if (item?.is_bike) {
                                    request.url = 'store_frame_list';
                                }
                                wsSend_request(ws, request)
                            })
                            // const request1 = {
                            //     "transmit": "broadcast",
                            //     "url": "store_frame_list",
                            //     "request" : {
                            //         "status" : "UNSOLD",
                            //         "limit" : "1000",
                            //         "from_date":"",
                            //         "to_date":"",
                            //         "order_type":"",
                            //         "warehouse_id":ws_onmessage?.response?.data?.order?.warehouse_id,
                            //         "product_id":"",
                            //         "page_no" : "1",
                            //         "search" : "",
                            //         "order_by" :"-updated_at",
                            //         "timezone":""
                            //     }
                            // }
                            // wsSend_request(ws, request1)
                        }
                    } else {
                        if (ws_onmessage?.DeviceId === device_id) {
                            dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "store_accessories_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(setStoreAccessoriesReport(ws_onmessage?.response?.data));
                    } else {
                        dispatch(setStoreAccessoriesReport(ws_onmessage?.response?.data));
                    };
                };
                if (ws_onmessage?.response?.status === 200) {
                    // dispatch(setStoreAccessoriesList(ws_onmessage?.response?.data))
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: ws_onmessage?.response?.data }))
                } else {
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: [] }))
                }
                break;
            case "so_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "so_approval",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "so_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "wh_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "wh_approval",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "dispatch_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDispatchList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetDispatchList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetDispatchList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "dispatch_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addDispatchList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_add",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "dispatch_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateDispatchList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_edit",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "courier_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            const data = ws_onmessage?.response?.data?.data?.map((item) => ({
                                ...item,
                                label: item?.courier_name,
                                value: item?.id
                            }))
                            dispatch(setStoreOptionCouriersList({ data: data, pagination: ws_onmessage?.response?.data?.pagination }))
                        } else {
                            dispatch(setStoreCouriersList(ws_onmessage?.response?.data));
                        }
                    } else {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(setStoreOptionCouriersList({ data: [], pagination: {} }))
                        } else {
                            dispatch(setStoreCouriersList({ data: [], pagination: {} }))
                        }
                    };
                };
                break;
            case "courier_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "courier_edit":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "order_dispatch":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_dispatch",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                        dispatch(StartCommonLoader(false));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_dispatch",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.response?.msg?.details !== undefined) {
                            toast.error(ws_onmessage?.response?.msg?.details[0]);
                        };
                        if (Object?.keys(ws_onmessage?.response?.msg)?.length > 0) {
                            toast.error(ws_onmessage?.response?.msg?.distance);
                        };
                        toast.error(ws_onmessage?.response?.msg);
                        dispatch(StartCommonLoader(false));
                    };
                };
                break;
            case "order_delivered":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "order_cancelled":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "order_pause":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_pause",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_pause",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "invoice_cancel":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "invoice_cancel",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "invoice_cancel",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage.response.msg) {
                            if (Array.isArray(ws_onmessage.response.msg)) {
                                ws_onmessage.response.msg.forEach(message => {
                                    if (typeof message?.msg === "string") {
                                        toast.error(message?.msg);
                                    } else {
                                        // Handle other types of messages if needed
                                    }
                                });
                            } else if (typeof ws_onmessage.response.msg === "string") {
                                toast.error(ws_onmessage.response.msg);
                            } else {
                                // Handle other types of messages if needed
                            }
                        }
                    };
                };
                break;
            case "stock_inward_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "stock_inward_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "stock_inward_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "stock_inward_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetStockInwardList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetStockInwardList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "box_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllBoxList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getBoxList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getBoxList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "box_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addBoxList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "box_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateBoxList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "purchase_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addPurchaseList(ws_onmessage?.response?.data));
                    
                    if (ws_onmessage?.DeviceId === device_id) {
                        let payload = JSON.stringify({
                            "name": ws_onmessage?.response?.data?.customer_name,
                            "email": ws_onmessage?.response?.data?.email,
                            "phoneNumber": ws_onmessage?.response?.data?.mobile,
                            "sourceOfPurchase": ws_onmessage?.response?.data?.franchise_name,
                            "bike": ws_onmessage?.response?.data?.product_name,
                            "frameNumber": ws_onmessage?.response?.data?.frame_number,
                            "dealerName": ws_onmessage?.response?.data?.franchise_name,
                            "invoice": "https://firebasestorage.googleapis.com/v0/b/emotorad-353612.appspot.com/o/files%2FDaisukyFancy-gxp71.ttf%2BThu%20Sep%2005%202024%2017%3A41%3A02%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=5642c7d7-0d38-478c-b75c-2f46dbe58f45",
                            "ageGroup": ws_onmessage?.response?.data?.age_group,
                            "usecase": ws_onmessage?.response?.data?.use_case,
                            "pincode": searchPincodeList?.pin?.pin_code,
                            "city": searchPincodeList?.city?.district_name,
                            "state": searchPincodeList?.state?.state_name,
                        });
                       
                        const response = await OmsWarrantyAPI(payload);
                        if(response?.status === 200) {
                            toast.success("Product registered successfully.");
                            if(getFrameList?.frame) {
                                if(getFrameList?.frame?.order_type !== "AO") {
                                    if(getFrameList?.frame?.order_type === "FO") {
                                        // if(getFrameList?.customer?.diwali_offer === true) {
                                        //     setTimeout(() => {
                                        //         dispatch(StartMainCommonLoader({
                                        //             url: "purchase_add",
                                        //             loader: false
                                        //         }));
                                        //         dispatch(ClearFormSetFutios({
                                        //             url: "purchase_add",
                                        //             action: ws_onmessage?.response?.status
                                        //         }));
                                        //         const redirect_url = `${process.env.REACT_APP_RESELL_REDIRECT_URL}?id=${response?.data?._id}`;
                                        //         window.open(redirect_url, '_blank');
                                        //     }, 2000);
                                        // } else {
                                        //     dispatch(StartMainCommonLoader({
                                        //         url: "purchase_add",
                                        //         loader: false
                                        //     }));
                                        //     dispatch(ClearFormSetFutios({
                                        //         url: "purchase_add",
                                        //         action: ws_onmessage?.response?.status
                                        //     }));
                                        // };
                                        setTimeout(() => {
                                            dispatch(StartMainCommonLoader({
                                                url: "purchase_add",
                                                loader: false
                                            }));
                                            dispatch(ClearFormSetFutios({
                                                url: "purchase_add",
                                                action: ws_onmessage?.response?.status
                                            }));
                                            const redirect_url = `${process.env.REACT_APP_RESELL_REDIRECT_URL}?id=${response?.data?._id}`;
                                            // window.open(redirect_url, '_blank', 'width=800,height=600');
                                            window.location.replace(redirect_url);
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            dispatch(StartMainCommonLoader({
                                                url: "purchase_add",
                                                loader: false
                                            }));
                                            dispatch(ClearFormSetFutios({
                                                url: "purchase_add",
                                                action: ws_onmessage?.response?.status
                                            }));
                                            const redirect_url = `${process.env.REACT_APP_RESELL_REDIRECT_URL}?id=${response?.data?._id}`;
                                            // window.open(redirect_url, '_blank', 'width=800,height=600');
                                            window.location.replace(redirect_url);
                                        }, 2000);
                                    };
                                } else {
                                    dispatch(StartMainCommonLoader({
                                        url: "purchase_add",
                                        loader: false
                                    }));
                                    dispatch(ClearFormSetFutios({
                                        url: "purchase_add",
                                        action: ws_onmessage?.response?.status
                                    }));
                                };
                            } else {
                                setTimeout(() => {
                                    dispatch(StartMainCommonLoader({
                                        url: "purchase_add",
                                        loader: false
                                    }));
                                    dispatch(ClearFormSetFutios({
                                        url: "purchase_add",
                                        action: ws_onmessage?.response?.status
                                    }));
                                    
                                    const redirect_url = `${process.env.REACT_APP_RESELL_REDIRECT_URL}?id=${response?.data?._id}`;
                                    // window.open(redirect_url, '_blank', 'width=800,height=600');
                                    window.location.replace(redirect_url);
                                }, 2000);
                                // if(ws_onmessage?.Dealers_info?.diwali_offer) {
                                //     setTimeout(() => {
                                //         dispatch(StartMainCommonLoader({
                                //             url: "purchase_add",
                                //             loader: false
                                //         }));
                                //         dispatch(ClearFormSetFutios({
                                //             url: "purchase_add",
                                //             action: ws_onmessage?.response?.status
                                //         }));
                                //         const redirect_url = `${process.env.REACT_APP_RESELL_REDIRECT_URL}?id=${response?.data?._id}`;
                                //         window.open(redirect_url, '_blank');
                                //     }, 2000);
                                // } else {
                                //     dispatch(StartMainCommonLoader({
                                //         url: "purchase_add",
                                //         loader: false
                                //     }));
                                //     dispatch(ClearFormSetFutios({
                                //         url: "purchase_add",
                                //         action: ws_onmessage?.response?.status
                                //     }));
                                // };
                            };
                        } else {
                            dispatch(StartMainCommonLoader({
                                url: "purchase_add",
                                loader: false
                            }));
                            toast.error(response?.error);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "purchase_add",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "purchase_add",
                            action: ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "get_frame_number":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(StartMainCommonLoader({
                        url: "get_frame_number",
                        loader: true
                    }));
                    dispatch(GetFrameList(ws_onmessage?.response?.data))
                    dispatch(ErrorMessageFromRedux({
                        API: "get_frame_number",
                        Error: "",
                    }));
                    setTimeout(()=>{
                        dispatch(StartMainCommonLoader({
                            url: "get_frame_number",
                            loader: false
                        }));
                    },1000)
                } else {
                    dispatch(StartMainCommonLoader({
                        url: "get_frame_number",
                        loader: false
                    }));
                    dispatch(GetFrameList({}))
                    if(ws_onmessage?.response?.msg === "Frame number already registered.") {
                        dispatch(ErrorMessageFromRedux({
                            API: "get_frame_number",
                            Error: ws_onmessage?.response?.msg
                        }));
                    } else {
                        dispatch(ErrorMessageFromRedux({
                            API: "get_frame_number",
                            Error: "Frame Number not found, please enter the product details"
                        }));
                    };
                    toast.error(ws_onmessage?.response?.msg);
                };
                break;
            case "unauthorized":
                CommonLogout(accessToken, dispatch);
                // window.location.replace(process.env.REACT_APP_BASE_URL+`user/del_login`);
                dispatch(clearRedux());
                break;
            default:
                return;
        }
    })
};

