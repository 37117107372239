/* eslint-disable */
import React, { useEffect } from "react";
import { Image } from 'react-bootstrap';
import LogoImage from "../../assets/img/logo-white.png";
import GuestLayout from "../Shared/GuestLayout";
import WelcomeIcon from "../../assets/img/WelcomePoasters.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { WELCOME_REGESTER_PRODUCT_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import { useDispatch } from "react-redux";

const Congrats = (props) => {
    const { setGetCurrentPath } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch()
    const pathname = useLocation();

    useEffect(() => {
        setGetCurrentPath(pathname);
    }, [ pathname ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
    }, [ pathname ]);

    return(<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
        <h1 className="logoheader">
            <Image src={LogoImage} alt="logo" />
        </h1>
        <div className="welcome_screen">
            <h4>Congratulations</h4>
            <p className="welcome_screen_pass">Your warranty has been <br/> registered</p>
            <div className="welcomerCenterImage">
                <img src={WelcomeIcon} alt="welcome" />
                {/* <p>You have been successful <br/> enrolled in our Reward Program</p> */}
            </div>
            <Link className="backlinkbtn" to={WELCOME_REGESTER_PRODUCT_URL}>
                <i className="bi bi-arrow-left-short"></i> &nbsp;Back to Register Product
            </Link>
        </div>
    </div>
    </GuestLayout>)
}

export default Congrats;