/* eslint-disable */
import React, { useEffect } from "react";
import { Button, Image } from 'react-bootstrap';
import LogoImage from "../../assets/img/logo-white.png";
import GuestLayout from "../Shared/GuestLayout";
import WelcomeIcon from "../../assets/img/WelcomePoasters.png";
import { useNavigate, useLocation } from "react-router-dom";
import { REGESTER_PRODUCT_URL } from "../Shared/constant";

const Welcome = (props) => {
    const { setGetCurrentPath } = props;
    const Navigate = useNavigate();
    const pathname = useLocation();

    useEffect(() => {
        setGetCurrentPath(pathname);
    }, [ pathname ]);

    return(<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
        <h1 className="logoheader">
            <Image src={LogoImage} alt="logo" />
        </h1>
        <div className="welcome_screen">
            <h4>Welcome to EMotorad</h4>
            <div className="welcomerCenterImage">
                <img src={WelcomeIcon} alt="welcome" />
                <p>Please ensure to register <br/> your warranty using your active <br/> mobile number and email address to <br/> fully avail of its benefits.</p>
            </div>
            <Button className="registerbtn" onClick={() => Navigate(REGESTER_PRODUCT_URL)}>Click here to register</Button>
        </div>
    </div>
    </GuestLayout>)
}

export default Welcome;